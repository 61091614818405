import React from 'react';
import { Link } from 'gatsby'
import Layout from '../components/Layout'
import '../styles/mentions.scss'

const PageMentions = () => {
    return (
        <Layout>
            <h1>Mentions légales</h1>
            <section className="mentions-section">
            <article>
                    <h2>Editeur</h2>
                    <p>Le site <strong><Link to='/'>www.b-com.xyz</Link></strong> est la propriété exclusive de <strong>Marylise Barthélémy</strong>, entrepreneure-salariée hébergée au sein de la SAS au capital variable :</p>
                    <div>
                        <p><strong>APPUY Créateurs</strong><br />Coopérative d'Activités et Emploi<br />
                        1, Avenue des Cottages<br />
                        63000 Clermont-Ferrand</p>
                        <p>SIRET : 480 038 546 00028<br />
                        Numéro de TVA intracommunautaire : FR 09 480 038 546<br />
                        Immatriculée au Registre du Commerce et des Sociétés de Clermont-Ferrand
                        </p>
                    </div>
         
                    <p>Directrice d'édition et de publication : <strong>Marylise Barthélémy</strong>.</p>
                    <p>Contactez la responsable de la publication : <strong>marylise.barthelemy@gmail.com</strong> ou au <strong>06.76.43.26.99</strong></p>
                    
                </article>

                <article>
                    <h2>Hébergement</h2>
                    <p>Le site <strong><Link to='/'>www.b-com.xyz</Link></strong> est hébergé par: </p>
                    <div><strong>Netlify</strong><br /> 610 22nd Street, Suite 315<br />CA 94107 San Francisco, USA<br /><a href="https://www.netlify.com/">www.netlify.com</a></div>
                </article>

                <article>
                    <h2>Propriété intellectuelle et contrefaçons</h2>
                    
                    <p><strong>Marylise Barthélémy</strong> est propriétaire des droits de propriété intellectuelle ou détient les droits d’usage sur tous les éléments accessibles sur le site, notamment les textes, images, graphismes, logo, icônes, sons, logiciels.</p>
                    <p>Toute reproduction, représentation, modification, publication, adaptation de tout ou partie des éléments du site, quel que soit le moyen ou le procédé utilisé, est interdite, <strong>sauf autorisation écrite préalable</strong> de Marylise Barthélémy.</p>
                    <p>Toute exploitation non autorisée du site ou de l’un quelconque des éléments qu’il contient sera considérée comme constitutive d’une contrefaçon et poursuivie conformément aux dispositions des articles L.335-2 et suivants du Code de Propriété Intellectuelle.</p>
                </article>

                <article id="credits">
                    <h2>Crédits</h2>
                    <p><a href="https://fr.freepik.com/photos-vecteurs-libre/affaires">Affaires vecteur créé par stories - fr.freepik.com</a></p>
                    <p><a href="https://fr.freepik.com/photos-vecteurs-libre/design">Design vecteur créé par stories - fr.freepik.com</a></p>
                    <p><a href="https://fr.freepik.com/photos-vecteurs-libre/affaires">Affaires vecteur créé par pikisuperstar - fr.freepik.com</a></p>
                    <p><a href="https://fr.freepik.com/photos-vecteurs-libre/affaires">Affaires vecteur créé par pikisuperstar - fr.freepik.com</a></p>
                    <p><a href="https://fr.freepik.com/photos-vecteurs-libre/fond">Fond vecteur créé par freepik - fr.freepik.com</a></p>
                    <p><a href='https://fr.freepik.com/vecteurs/affaires'>Affaires vecteur créé par freepik - fr.freepik.com</a></p>
                    <p><a href='https://www.freepik.com/vectors/business'>Business vector created by freepik - www.freepik.com</a></p>
                    <p><a href='https://fr.freepik.com/vecteurs/infographie'>Infographie vecteur créé par freepik - fr.freepik.com</a></p>
                    <p><a href='https://fr.freepik.com/vecteurs/design'>Design vecteur créé par stories - fr.freepik.com</a></p>
                    <p><a href='https://fr.freepik.com/psd/maquette'>Maquette PSD créé par zlatko_plamenov - fr.freepik.com</a></p>
                    <p><a href='https://fr.freepik.com/vecteurs/couvrir'>Couvrir vecteur créé par macrovector - fr.freepik.com</a></p>
                    <p><a href='https://www.freepik.com/vectors/background'>Background vector created by upklyak - www.freepik.com</a></p>
                </article>
            </section>
        </Layout>
    );
};

export default PageMentions;